import { Injectable } from "@angular/core";
import { FileDto, FileServiceProxy, TargetFeature, TempFileDto } from "@shared/service-proxies/service-proxies";
import * as _ from "lodash";

@Injectable()
export class FileDownloadService {

    constructor(private _fileService: FileServiceProxy) {
    }

    downloadTempFile(file: FileDto) {
        const dto = new TempFileDto({ fileToken: file.fileToken });
        this._fileService.downloadTempFile(dto).subscribe(result => {
            const byteCharacters = window.atob(result.fileContents);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([new Uint8Array(byteNumbers)], { type: file.fileType });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = file.fileName;
            link.click();
        });
    }

    saveObjectAsCsv(data: any, fileName: string, xAxisList) {
        let uniqueValues = [];
        if (xAxisList.length == 0)
            uniqueValues = Array.from(new Set(data.yAxis.flatMap(yAxis => yAxis.values.map(item => this.formatDate(item.dateTime))))).sort();
        else
            uniqueValues = data.yAxis.flatMap(yAxis => yAxis.values.map(item => item.xAxis)).sort();


        const mergedData = [];
        let isUnit = false;
        let unitNames = _.groupBy(data.yAxis, (x) => x.unitName);
        _.forEach(unitNames, (_, key) => {
            isUnit = key == "" ? false : true;
            let uuid = unitNames[key][0].uuid;
            uniqueValues.forEach(value => {
                let row: { [key: string]: any } = {}; // Boş bir nesne olarak başlat
                if (xAxisList.length == 0) {
                    row = { DateTime: value };
                } else {
                    let xAxisName = "xAxis : " + xAxisList[0].name;
                    row = { [xAxisName]: value };
                }
                if (isUnit)
                    row.Unit = key;
                let filterUnitNames = data.yAxis.filter(x => x.unitName == key);
                filterUnitNames.forEach(yAxis => {
                    const matchingValue = yAxis.values.find(item => item.uuid === uuid && (xAxisList.length == 0 ? this.formatDate(item.dateTime) === value : item.xAxis === value));
                    if (matchingValue) {
                        row[yAxis.name] = matchingValue.value;
                        yAxis.values.splice(matchingValue, 1);
                    }
                });

                if (Object.keys(row).length > 1) {
                    mergedData.push(row);
                }
            });
        });

        const headerSet = new Set(["Unit"]);
        if (!isUnit)
            headerSet.delete("Unit");
        mergedData.forEach(row => {
            Object.keys(row).forEach(key => {
                headerSet.add(key);
            });
        });

        const header = Array.from(headerSet);
        const replacer = (key, value) => (value === null ? "" : value);
        // CSV oluşturma işlemi
        const csv = mergedData.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
        );
        csv.unshift(header.join(","));

        const csvArray = csv.join("\r\n");

        const a = document.createElement("a");
        const blob = new Blob([csvArray], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName + ".csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay 0'dan başladığı için +1
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // İstenilen formatta döndür
    }
}
