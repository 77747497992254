import { Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ModalDirective } from "@node_modules/ngx-bootstrap/modal";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ReportDto, ReportsServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    selector: "perDeleteReportModal",
    templateUrl: "./per-delete-report.component.html",
    styleUrls: ["./per-delete-report.component.css"],
    encapsulation: ViewEncapsulation.None,
})
export class PerDeleteReportComponent extends AppComponentBase {
    @ViewChild("createOrEditModal", { static: true }) modal: ModalDirective;
    @Output() updateDashboard: EventEmitter<any> = new EventEmitter<any>();
    @Input() pageName = "";
    report: ReportDto;
    saving = false;

    constructor(injector: Injector, private _reportsServiceProxy: ReportsServiceProxy) {
        super(injector);
    }

    show(item: ReportDto): void {
        this.report = item;
        this.saving = false;
        this.modal.show();
    }

    close(): void {
        this.saving = true;
        this.modal.hide();
    }

    delete() {
        this._reportsServiceProxy.delete(this.report.id).subscribe((data) => {
            this.saving = true;
            // this.showInfoNotify(this.l("SavedSuccessfully"));
            this.showInfoNotify(this.l("SavedSuccessfully"));
            this.updateDashboard.emit(this.report);
            this.modal.hide();
        });
    }
}
