import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import { SeriesOptionsType } from "@node_modules/highcharts/highstock";
import { AxisLabelsFormatterCallbackFunction, TooltipOptions } from "@node_modules/highcharts";
import HighchartsData from "@node_modules/highcharts/modules/data";
import HighchartsAccessibility from "@node_modules/highcharts/modules/accessibility";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";

HighchartsData(Highcharts);
HighchartsAccessibility(Highcharts);

export interface BubbleColumnData {
    subtitle?: string;
    categories?: string[];
    series?: SeriesOptionsType[];
    formatter?: AxisLabelsFormatterCallbackFunction;
    tooltip?: TooltipOptions;
    xAxisTitle?: string;
    yAxisTitle?: string;
}

@Component({
    selector: "app-bubble-chart",
    templateUrl: "./bubble-chart.component.html",
    styleUrls: ["./bubble-chart.component.css"],
})
export class BubbleChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() bubbleColumnData: BubbleColumnData;
    @Input() completedEventId: string;
    @Input() legendEnabled: boolean = true;

    chart: Highcharts.Chart;
    options: Highcharts.Options;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    ngOnInit(): void {
        this.setOptions();
        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);
        this.chart = Highcharts.chart(
            // @ts-ignore
            chart,
            Highcharts.merge(this.options, {
                xAxis: {
                    gridLineWidth: 1,
                    title: {
                        text: this.bubbleColumnData.xAxisTitle,
                    },
                },
                yAxis: {
                    startOnTick: false,
                    endOnTick: false,
                    title: {
                        text: this.bubbleColumnData.yAxisTitle,
                    },
                    maxPadding: 0.2,
                },
            })
        );

        // if (Highcharts.charts.find((v) => v["renderTo"].id == this.chartName)) {
        //     Highcharts.charts.find((v) => v["renderTo"].id == this.chartName).reflow();
        // }

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            20, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const bubbleChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (bubbleChart) {
                    bubbleChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.chart,
                type: DashboardItemWidgetType.chart,
            });
        }, 2000);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ("bubbleColumnData" in changes && !changes.bubbleColumnData.firstChange) {
            this.chart.update({
                ...this.options,
                tooltip: this.bubbleColumnData.tooltip,
                series: this.bubbleColumnData.series,
                xAxis: {
                    gridLineWidth: 1,
                    title: {
                        text: this.bubbleColumnData.xAxisTitle,
                    },
                },
                yAxis: {
                    startOnTick: false,
                    endOnTick: false,
                    title: {
                        text: this.bubbleColumnData.yAxisTitle,
                    },
                    maxPadding: 0.2,
                },
            });
        }
    }

    setOptions() {
        this.options = {
            chart: {
                type: "bubble",
                zooming: {
                    type: 'xy'
                }
            },
            title: {
                text: null,
            },
            subtitle: {
                text: this.bubbleColumnData.subtitle,
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                gridLineWidth: 1,
                title: {
                    text: this.bubbleColumnData.xAxisTitle,
                },
            },
            yAxis: {
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: this.bubbleColumnData.yAxisTitle,
                },
                maxPadding: 0.2,
            },
            accessibility: {
                point: {
                    valueDescriptionFormat: "{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.",
                },
            },
            tooltip: this.bubbleColumnData.tooltip,
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}",
                    },
                },
                bubble: {
                    jitter: {
                        x: 0.1, // X ekseninde dağılım oranı
                        y: 0.1  // Y ekseninde dağılım oranı
                    }
                }
            },
            series: this.bubbleColumnData.series,
            credits: {
                enabled: false,
            },
        };
    }
}
