import {Component, EventEmitter, Injector, Input, Output, ViewChild} from '@angular/core';
import {AppComponentBase} from "@shared/common/app-component-base";
import {ModalDirective} from "@node_modules/ngx-bootstrap/modal";
import {
    ReportDashboardDto,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'shareDashboardModal',
    templateUrl: './share-dashboard.component.html',
    styleUrls: ['./share-dashboard.component.css']
})
export class ShareDashboardComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', {static: true}) modal: ModalDirective;
    @Output() updateDashboard: EventEmitter<any> = new EventEmitter<any>();
    @Input() pageName = "";
    dashboard: ReportDashboardDto;
    saving = false;
    typeFilter = 10;

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
    ) {
        super(injector);
    }

    show(item: ReportDashboardDto): void {
        this.dashboard = item;
        this.saving = false;
        this.typeFilter = 10;
        this.modal.show();
    }

    close(): void {
        this.saving = true;
        this.modal.hide();
    }

    share() {
        let item = {...this.dashboard};
        item.shareType = this.typeFilter;
        this._reportDashboardServiceProxy.updateReportDashboardShareType((item as ReportDashboardDto)).subscribe((data) => {
            this.saving = true;
            this.showInfoNotify(this.l('SavedSuccessfully'));
            this.updateDashboard.emit(this.dashboard);
            this.modal.hide();
        });
    }

}
