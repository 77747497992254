import { Component, Injector, Input, OnInit } from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    BaseComponentDto,
    BaseComponentsServiceProxy,
    DowntimesFaultCodeAnalysisResponse, DowntimesServiceProxy,
    ModelsServiceProxy,
    PowerPlant,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";
import * as _ from "lodash";
import { Store } from "@node_modules/@ngrx/store";
import { GeneralWidgetConfigDto } from "@app/shared/models/GeneralWidgetConfigDto";
import { EditWidgetConfigDto } from "@app/state-management/models/edit-widget-config";
import { EditWidgetAction } from "@app/state-management/actions/edit-widget.action";

@Component({
    selector: "edit-general",
    templateUrl: "./edit-general.component.html",
    styleUrls: ["./edit-general.component.scss"]
})
export class EditGeneralComponent extends AppComponentBase implements OnInit {
    @Input() powerPlant: PowerPlant;
    searchvalue = "";
    selected: boolean = false;
    value: number = 3;
    options: Options = {
        ceil: 8,
        floor: 0,
        showSelectionBar: true,
        showTicks: true
    };
    name = "";
    description = "";
    config: EditWidgetConfigDto;
    generalWidgetConfigDto: GeneralWidgetConfigDto;
    temperatureBaseComponents: BaseComponentDto[];
    selectedBaseComponentIdId: number;
    selectedTopRecordFilter: number;
    selectedMainStatus: any;

    AcceptedChartNamesForSelectingBaseComponentList = {
        PowerCurve: "Temperature Scatter Analysis",
        TemperatureTrendAnalysis: "Temperature Trend Analysis"
    };
    readonly AcceptedChartNamesForSelectingBaseComponents: string[] = Object.values(
        this.AcceptedChartNamesForSelectingBaseComponentList
    );
    isActiveForSelectingBaseComponents: boolean = false;
    isFilterTopRecords: boolean = false;
    isFilterErrorCode: boolean = false;
    filterTopRecordFilters?: number[] = [];
    downtimeStatusCodeResponse: DowntimesFaultCodeAnalysisResponse[];
    private selectedUnitModelId: any;

    constructor(
        injector: Injector,
        private _baseComponentsServiceProxy: BaseComponentsServiceProxy,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _modelsService: ModelsServiceProxy,
        private _downtimesServiceProxy: DowntimesServiceProxy,
        private store: Store
    ) {
        super(injector);
    }

    ngOnInit() {
        this.store
            .select((state) => state)
            .subscribe(async (state: any) => {
                if (!_.isNil(state.editWidget.data.general)) {
                    this.config = state.editWidget.data;
                    this.name = "";
                    this.description = "";
                    this.name = this.config?.general?.name ?? "";
                    this.description = this.config?.general?.description ?? "";

                    this.isFilterTopRecords = this.config?.general?.isFilterTopRecords ?? false;
                    this.isFilterErrorCode = this.config?.general?.isFilterErrorCode ?? false;
                    this.filterTopRecordFilters = this.config?.general?.filterTopRecordFilters ?? [];
                    this.selectedTopRecordFilter = this.config?.general?.selectedTopRecordFilter ?? 0;

                    await this.getAllModels();

                    if (this.isFilterErrorCode) {
                        this._downtimesServiceProxy
                            .getDowntimesFaultCodeStatuCodes(undefined, undefined, this.powerPlant.id, new Date().getTimezoneOffset() / 60)
                            .subscribe((response) => {
                                response = response.filter((x) => x.mainStatusText != null);
                                this.downtimeStatusCodeResponse = response;

                                if (this.config.general.selectedMainStatus) {
                                    this.selectedMainStatus = Number(this.config.general.selectedMainStatus);
                                } else {
                                    this.selectedMainStatus = "";
                                }
                            });
                    }

                    this.setAcceptedChartNamesForSelectingBaseComponents();
                    this.subscribeToEvent("app-edit-widget-layout", (response) => {
                        this.setAcceptedChartNamesForSelectingBaseComponents();
                    });
                }
            });
    }

    public ShowOverlay(overlayName, animationName) {
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    }

    modelChange() {
        this.generalWidgetConfigDto = new GeneralWidgetConfigDto();
        this.generalWidgetConfigDto.visible = true;
        this.generalWidgetConfigDto.name = this.name;
        this.generalWidgetConfigDto.description = this.description;
        this.generalWidgetConfigDto.selectedTopRecordFilter = this.selectedTopRecordFilter;
        this.generalWidgetConfigDto.isFilterTopRecords = this.isFilterTopRecords;
        this.generalWidgetConfigDto.isFilterErrorCode = this.isFilterErrorCode;
        this.generalWidgetConfigDto.filterTopRecordFilters = this.filterTopRecordFilters;

        let item = Object.assign(new EditWidgetConfigDto(), {
            component: this.config.component,
            divId: this.config.divId,
            widgetId: this.config.widgetId,
            general: this.generalWidgetConfigDto,
            datasource: this.config.datasource,
            series: this.config.series,
            format: this.config.format,
            axex: this.config.axex,
            unitModel: this.config.unitModel,
            baseComponent: this.config.baseComponent
        });

        this.store.dispatch(EditWidgetAction(item));
        abp.event.trigger(this.config.divId + "-general", this.generalWidgetConfigDto);
    }

    onBaseComponentChange(baseComponentId: number) {
        const baseComponent = this.temperatureBaseComponents.find((x) => x.id == baseComponentId);

        let item = Object.assign(new EditWidgetConfigDto(), {
            component: this.config.component,
            divId: this.config.divId,
            widgetId: this.config.widgetId,
            general: this.config.general,
            datasource: this.config.datasource,
            series: this.config.series,
            format: this.config.format,
            axex: this.config.axex,
            unitModel: this.config.unitModel,
            baseComponent: baseComponent,
            defaultWidgetName: this.config.defaultWidgetName
        });

        this.store.dispatch(EditWidgetAction(item));
        // abp.event.trigger(this.config.divId + "-general", this.generalWidgetConfigDto);

        this.isActiveForSelectingBaseComponents = true;
        abp.event.trigger(this.config.divId + "-temp-base-component-id", baseComponentId);
    }

    onSelectedMainStatus(selectedMainStatus) {
        this.selectedMainStatus = selectedMainStatus;

        this.generalWidgetConfigDto = new GeneralWidgetConfigDto();
        this.generalWidgetConfigDto.visible = true;
        this.generalWidgetConfigDto.name = this.name;
        this.generalWidgetConfigDto.description = this.description;
        this.generalWidgetConfigDto.selectedTopRecordFilter = this.selectedTopRecordFilter;
        this.generalWidgetConfigDto.selectedMainStatus = selectedMainStatus;
        this.generalWidgetConfigDto.isFilterTopRecords = this.isFilterTopRecords;
        this.generalWidgetConfigDto.isFilterErrorCode = this.isFilterErrorCode;
        this.generalWidgetConfigDto.filterTopRecordFilters = this.filterTopRecordFilters;

        let item = Object.assign(new EditWidgetConfigDto(), {
            component: this.config.component,
            divId: this.config.divId,
            widgetId: this.config.widgetId,
            general: this.generalWidgetConfigDto,
            datasource: this.config.datasource,
            series: this.config.series,
            format: this.config.format,
            axex: this.config.axex,
            unitModel: this.config.unitModel
        });
        this.store.dispatch(EditWidgetAction(item));

        abp.event.trigger(this.config.divId + "-downtime-error-code", this.selectedMainStatus);
        abp.event.trigger(this.config.divId + "-general", this.generalWidgetConfigDto);
    }

    onselectedTopRecordFilter(selectedTopRecordFilter: number) {
        this.selectedTopRecordFilter = selectedTopRecordFilter;

        this.generalWidgetConfigDto = new GeneralWidgetConfigDto();
        this.generalWidgetConfigDto.visible = true;
        this.generalWidgetConfigDto.name = this.name;
        this.generalWidgetConfigDto.description = this.description;
        this.generalWidgetConfigDto.selectedTopRecordFilter = selectedTopRecordFilter;
        this.generalWidgetConfigDto.isFilterTopRecords = this.isFilterTopRecords;
        this.generalWidgetConfigDto.isFilterErrorCode = this.isFilterErrorCode;
        this.generalWidgetConfigDto.filterTopRecordFilters = this.filterTopRecordFilters;

        let item = Object.assign(new EditWidgetConfigDto(), {
            component: this.config.component,
            divId: this.config.divId,
            widgetId: this.config.widgetId,
            general: this.generalWidgetConfigDto,
            datasource: this.config.datasource,
            series: this.config.series,
            format: this.config.format,
            axex: this.config.axex,
            unitModel: this.config.unitModel
        });
        this.store.dispatch(EditWidgetAction(item));

        abp.event.trigger(this.config.divId + "-top-record-filter", this.selectedTopRecordFilter);
        abp.event.trigger(this.config.divId + "-general", this.generalWidgetConfigDto);
    }

    private setAcceptedChartNamesForSelectingBaseComponents() {
        this.isActiveForSelectingBaseComponents = this.AcceptedChartNamesForSelectingBaseComponents.includes(
            this.config.defaultWidgetName
        );

        if (this.isActiveForSelectingBaseComponents) {
            this.getTemperatureBaseComponentsFunction();
        }
    }

    private getTemperatureBaseComponentsFunction() {
        this._baseComponentsServiceProxy
            .getTemperatureBaseComponents(this.powerPlant.id, this.selectedUnitModelId)
            .subscribe((data) => {
                this.temperatureBaseComponents = data;
                if (this.config?.baseComponent?.id) {
                    this.selectedBaseComponentIdId = this.config.baseComponent.id;
                } else {
                    this.selectedBaseComponentIdId = this.temperatureBaseComponents[0].id;
                }
            });
    }

    async getAllModels() {
        return new Promise((resolve, reject) => {
            this._modelsService.getAllModelsForLookupTable(this.powerPlant.id).subscribe((result) => {
                if (this.config.unitModel) {
                    if (this.config.unitModel.unitModelId) {
                        this.selectedUnitModelId = this.config.unitModel.unitModelId;
                    } else {
                        this.selectedUnitModelId = result[0].id;
                    }
                }
                resolve(result);
            });
        });
    }
}
