<div class="company-drawer screen" *ngIf="isCompanyDrawerOpen">
    <div class="bottom-drawer-company">
        <div *ngIf="isHybridPowerPlant" class="general-ecommerce-widget-summary">
            <div class="general-ecommerce-widget-summary-content">
                <div class="frame-110">
                    <div
                        class="product-sold publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.total-net-generation"] }}
                    </div>
                    <div class="number-longrand publicsans-bold-white-24px">
                        <span class="publicsans-bold-charade-24px">{{ this.currentTotalNetGenerationValue }}</span><span
                        class="publicsans-bold-charade-18px">/{{ this.totalCurrentInstantPower }}</span><span
                        class="publicsans-bold-charade-24px">&nbsp;</span>
                    </div>
                    <div class="number-longrand-1 number-longrand-3 publicsans-semi-bold-pale-sky-10px">MW</div>
                </div>
                <div class="chart-radial">
                    <app-circle [color]="'success'" [percentage]="this.instantPercentage"></app-circle>
                </div>
            </div>

        </div>


        <!--        DAILY NET GENERATION-->
        <div *ngIf="isHybridPowerPlant" class="general-ecommerce-widget-summary">
            <div class="general-ecommerce-widget-summary-content">
                <div class="frame-110">
                    <div
                        class="product-sold publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.daily-net-generation"] }}
                    </div>
                    <div class="number-longrand publicsans-bold-white-24px">
                        <span class="publicsans-bold-charade-24px">{{ this.dailyNetGeneration }}</span><span
                        class="publicsans-bold-charade-18px">/{{ this.totalTenantAllCapacity }}</span><span
                        class="publicsans-bold-charade-24px">&nbsp;</span>
                    </div>
                    <div class="number-longrand-1 number-longrand-3 publicsans-semi-bold-pale-sky-10px">MWh</div>
                </div>

                <div class="chart-radial">
                    <app-circle [color]="'warning'" [percentage]="this.dailyNetGenerationPercentage"></app-circle>
                </div>
            </div>

        </div>


        <div class="general-ecommerce-widget-summary">
            <div class="general-ecommerce-widget-summary-content">
                <div class="frame-110">
                    <div
                        class="product-sold publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.active-power"] }}
                    </div>
                    <div class="number-longrand publicsans-bold-white-24px">
                        <span class="publicsans-bold-charade-24px">{{ this.currentInstantPower }}</span><span
                        class="publicsans-bold-charade-18px">/{{ this.totalCurrentInstantPower }}</span><span
                        class="publicsans-bold-charade-24px">&nbsp;</span>
                    </div>
                    <div class="number-longrand-1 number-longrand-3 publicsans-semi-bold-pale-sky-10px">MW</div>
                </div>
                <div class="chart-radial">
                    <app-circle [color]="'success'" [percentage]="this.instantPercentage"></app-circle>
                </div>
            </div>

        </div>


        <div class="general-ecommerce-widget-summary">
            <div class="general-ecommerce-widget-summary-content">
                <div class="frame-110">
                    <div
                        class="product-sold publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.daily-total"] }}
                    </div>
                    <div class="number-longrand publicsans-bold-white-24px">
                        <span class="publicsans-bold-charade-24px">{{ this.totalDailyProduction }}</span><span
                        class="publicsans-bold-charade-18px">/{{ this.totalTenantAllCapacity }}</span><span
                        class="publicsans-bold-charade-24px">&nbsp;</span>
                    </div>
                    <div class="number-longrand-1 number-longrand-3 publicsans-semi-bold-pale-sky-10px">MWh</div>
                </div>

                <div class="chart-radial">
                    <app-circle [color]="'warning'" [percentage]="this.totalDailyPercentage"></app-circle>
                </div>
            </div>

        </div>


        <div class="general-ecommerce-widget-summary" *ngIf="powerPlantTypes.includes(PowerPlantType.Wind)">
            <div class="frame-110-1">
                <div class="stack">
                    <div class="icon-2 icon-5">
                        <div class="ic_tabler_building-wind-turbine"></div>
                    </div>
                    <div
                        class="product-sold-1 publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.wind-generation"] }}
                    </div>
                </div>
                <div class="number-longrand publicsans-bold-white-24px">
                    <span class="publicsans-bold-charade-24px">{{ this.currentAllWindPower }}</span><span
                    class="publicsans-bold-charade-18px">/{{ this.windPowerPlantsCapacity.toFixed(2) }}</span><span
                    class="publicsans-bold-charade-24px">&nbsp;</span><span
                    class="publicsans-bold-pale-sky-18px">MW</span>
                </div>
                <div class="stack">
                    <div class="icon icon-5">
                        <div class="ic_tabler_calendar ic_tabler_calendar-5"></div>
                    </div>
                    <div
                        class="number-longrand-2 valign-text-middle number-longrand-3 publicsans-semi-bold-white-14px">
                  <span><span
                      class="publicsans-semi-bold-charade-14px">{{ _.round(this.totalDailyWindProduction, 2) }} </span><span
                      class="publicsans-semi-bold-pale-sky-10px">MWh</span>
                  </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="general-ecommerce-widget-summary" *ngIf="powerPlantTypes.includes(PowerPlantType.Solar)">
            <div class="frame-110-1">
                <div class="stack">
                    <div class="icon-3 icon-5">
                        <div class="ic_tabler-sun"></div>
                    </div>
                    <div
                        class="product-sold-1 publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.solar-generation"] }}
                    </div>
                </div>
                <div class="number-longrand publicsans-bold-white-24px">
                    <span class="publicsans-bold-charade-24px">{{ this.currentAllSolarPower }}</span><span
                    class="publicsans-bold-charade-18px">/{{ this.solarPowerPlantsCapacity.toFixed(2) }}</span><span
                    class="publicsans-bold-charade-24px">&nbsp;</span><span
                    class="publicsans-bold-pale-sky-18px">MW</span>
                </div>
                <div class="stack">
                    <div class="icon icon-5">
                        <div class="ic_tabler_calendar-1 ic_tabler_calendar-5"></div>
                    </div>
                    <div
                        class="number-longrand-2 valign-text-middle number-longrand-3 publicsans-semi-bold-white-14px">
                  <span><span
                      class="publicsans-semi-bold-charade-14px">{{ _.round(this.totalDailySolarProduction, 2) }} </span><span
                      class="publicsans-semi-bold-pale-sky-10px">MWh</span>
                  </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="general-ecommerce-widget-summary" *ngIf="powerPlantTypes.includes(PowerPlantType.Coal)">
            <div class="frame-110-1">
                <div class="stack">
                    <div class="icon-4 icon-5">
                        <img class="iconsic_replace"
                             src="../../../../../assets/common/images/map/company-drawer/icons-ic-replace.svg"
                             alt="icons/ic_replace">
                    </div>
                    <div class="product-sold-1 publicsans-semi-bold-charade-14px">Thermal Production</div>
                </div>
                <div class="number-longrand publicsans-bold-white-24px">
                    <span class="publicsans-bold-charade-24px">75</span><span
                    class="publicsans-bold-charade-18px">/1019</span><span
                    class="publicsans-bold-charade-24px">&nbsp;</span><span
                    class="publicsans-bold-pale-sky-18px">MW</span>
                </div>
                <div class="stack">
                    <div class="icon icon-5">
                        <div class="ic_tabler_calendar-2 ic_tabler_calendar-5"></div>
                    </div>
                    <div
                        class="number-longrand-2 valign-text-middle number-longrand-3 publicsans-semi-bold-white-14px">
                  <span><span class="publicsans-semi-bold-charade-14px">1,368 </span><span
                      class="publicsans-semi-bold-pale-sky-10px">MWh</span>
                  </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="general-ecommerce-widget-summary" *ngIf="powerPlantTypes.includes(PowerPlantType.Hydro)">
            <div class="frame-110-1">
                <div class="stack">
                    <div class="icon-1 icon-5">
                        <img class="iconsic_replace"
                             src="../../../../../assets/common/images/map/company-drawer/icons-ic-replace.svg"
                             alt="icons/ic_replace">
                    </div>
                    <div class="product-sold-1 publicsans-semi-bold-charade-14px">{{ dynamicPropertiesKeyValueList["company-drawer.text.hydro-generation"] }}</div>
                </div>
                <div class="number-longrand publicsans-bold-white-24px">
                    <span class="publicsans-bold-charade-24px">{{ this.currentAllHydroPower }}</span><span
                    class="publicsans-bold-charade-18px">/{{ this.hydroPowerPlantsCapacity.toFixed(2) }}</span><span
                    class="publicsans-bold-charade-24px">&nbsp;</span><span
                    class="publicsans-bold-pale-sky-18px">MW</span>
                </div>
                <div class="stack">
                    <div class="icon icon-5">
                        <div class="ic_tabler_calendar-3 ic_tabler_calendar-5"></div>
                    </div>
                    <div
                        class="number-longrand-2 valign-text-middle number-longrand-3 publicsans-semi-bold-white-14px">
                  <span><span
                      class="publicsans-semi-bold-charade-14px">{{ _.round(this.totalDailyHydroProduction, 2) }} </span><span
                      class="publicsans-semi-bold-pale-sky-10px">MWh</span>
                  </span>
                    </div>
                </div>
            </div>
        </div>


        <article class="close-icon" (click)="closeCompanyView()">
            <div>
                <img class="iconsic_close"
                     src="../../../../../assets/common/images/map/company-drawer/icons-ic-close-1.svg"
                     alt="icons/ic_close"></div>
        </article>
    </div>
</div>
