import {
    AbpMultiTenancyService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    NotifyService,
    PermissionCheckerService,
    SettingService,
} from "abp-ng2-module";
import { Component, Inject, Injector, OnDestroy, ViewChild } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppUrlService, IOT_DATA_CONSUMER_API_BASE_URL } from "@shared/common/nav/app-url.service";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { AppUiCustomizationService } from "@shared/common/ui/app-ui-customization.service";
import { PrimengTableHelper } from "shared/helpers/PrimengTableHelper";
import { UiCustomizationSettingsDto } from "@shared/service-proxies/service-proxies";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerTextService } from "@app/shared/ngx-spinner-text.service";
import { HttpClient } from "@angular/common/http";
import Handsontable from "handsontable";
import { HotTableRegisterer } from "@node_modules/@handsontable/angular";
import { ProxyTableComponent } from "@app/shared/common/proxy-table/proxy-table.component";
import * as OHash from "object-hash";
import { InfoModalDto } from "@app/shared/common/info-modal/info-modal.component";
import { ToastrService } from "ngx-toastr";

interface AbpEventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: "",
})
export abstract class AppComponentBase implements OnDestroy {
    intervalTimer?: NodeJS.Timer;

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    eventSubscriptions: AbpEventSubscription[] = [];
    iot_data_consumer_baseUrl: string;
    http: HttpClient;

    hotTableId: string;
    hotRegisterer = new HotTableRegisterer();
    hotData = [];
    columnHeaders = [];
    rowHeaders = [];
    columns = [];
    columnSummary = [];
    lastDataHash: String;
    localTimeZone = "Europe/Istanbul"; // Türkiye'nin saat dilimi
    timezoneOffset = -abp.clock.now().getTimezoneOffset() / 60;
    toastr: ToastrService; // ToastrService tanımı

    @ViewChild("proxyTable", { static: true }) localProxyTable: ProxyTableComponent;

    private ngxSpinnerTextService: NgxSpinnerTextService;
    private defaultConnectionStringSecretName = "eu-iot-db-read-cs";
    private automationTestConnectionStringSecretName = "automation-test";

    constructor(injector: Injector, @Inject(IOT_DATA_CONSUMER_API_BASE_URL) iot_data_consumer_baseUrl?: string) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.iot_data_consumer_baseUrl =
            iot_data_consumer_baseUrl !== undefined && iot_data_consumer_baseUrl !== null ? iot_data_consumer_baseUrl : "";
        this.http = injector.get(HttpClient);
        this.toastr = injector.get(ToastrService); // ToastrService injector
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === "fluid") {
            return "container-fluid";
        } else if (this.appSession.theme.baseSettings.layout.layoutType === "fluid-xxl") {
            return "container-xxl";
        }

        return "container";
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    flattenDeep(array) {
        return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), []);
    }

    subscribeFilterEvents(cb: (...args: any[]) => void) {
        this.subscribeToEvent("onFilterSave", cb);
        this.subscribeToEvent("filterTypeChanged", cb);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    emptyHandsontableSettings(): Handsontable.GridSettings {
        return {
            minSpareCols: 0,
            startRows: 0,
            columns: [],
        };
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        this.unsubscribeToEvent(eventName);
        abp.event.on(eventName, callback);
        this.eventSubscriptions.push({
            eventName,
            callback,
        });
    }

    protected unsubscribeToEvent(eventName: string): void {
        const event = this.eventSubscriptions.find((x) => x.eventName == eventName);
        if (event) {
            abp.event.off(event.eventName, event.callback);
            this.eventSubscriptions = this.eventSubscriptions.filter((x) => x.eventName != event.eventName);
        }
    }

    public unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }

    getConnectionStringSecretName(): string {
        const isAutomationTest = this.appSession.tenancyName === this.automationTestConnectionStringSecretName;

        const tenantSpecificDbName = `${this.appSession.tenancyName}-iot-db-read-cs`;

        return isAutomationTest ? this.defaultConnectionStringSecretName : tenantSpecificDbName;
    }

    getHandsontable(id?: string | undefined): Handsontable {
        if (id || this.hotTableId || this.getProxyTableId()) {
            return this.hotRegisterer.getInstance(id || this.hotTableId || this.getProxyTableId());
        }
        return undefined;
    }

    getProxyTableId(): string {
        if (this.localProxyTable) {
            return this.localProxyTable.hotTableId;
        }
    }

    ngAfterViewInit(): void {
        const ht = this.getHandsontable();
        if (ht != null) {
            let t = this;
            ht.addHook("afterLoadData", (cellValues) => t.hotAfterLoadData(cellValues.length > 0, ht));
            ht.addHook("afterChange", (chang) => t.hotAfterChange(chang));
        }
    }

    hotAfterChange(changes: any, forceChange?: boolean | undefined, table?: Handsontable | undefined) {
        const ht = table ? table : this.getHandsontable();
        if (forceChange) {
            this.hasHotChanged = true;
            this.lastDataHash = null;
        } else if (changes != null && (changes.length < 1 || changes[0].length < 4 || changes[0][2] !== changes[0][3])) {
            const hashData = OHash(ht.getSourceData().toString());
            this.hasHotChanged = hashData !== this.lastDataHash;
        }
    }

    hotAfterLoadData(initialLoad: boolean = false, table?: Handsontable | undefined) {
        const ht = table ? table : this.getHandsontable();
        this.lastDataHash = OHash(ht.getSourceData());
        this.hasHotChanged = false;
    }

    get hasHotChanged(): boolean {
        return this.appSession.hasHotChanged;
    }

    set hasHotChanged(val: boolean) {
        this.appSession.hasHotChanged = val;
    }

    handleErrorResponse(error: any) {
        if (error.response) {
            let response = JSON.parse(error.response);
            this.message.error(response.error.message, null, { isModalPopup: true });
        } else {
            this.message.error(error.message, null, { isModalPopup: true });
        }
    }

    initializeArchiveModalDto<T>(archiveModalDto: InfoModalDto<T>) {
        archiveModalDto.isLinkTextVisible = false;
        archiveModalDto.isCancelButtonVisible = true;
        archiveModalDto.headerTitleClass = "fs-6 fw-boldest";
        archiveModalDto.headerIconSrc = "/assets/common/icons/archive-dark.svg";
        archiveModalDto.buttonClass = "btn btn-sm btn-warning";
        archiveModalDto.buttonIconSrc = "/assets/common/icons/archive-white.svg";
        archiveModalDto.buttonText = this.l("Archive");
        archiveModalDto.buttonTextClass = "text-white";
    }

    initializeUnarchiveModalDto<T>(archiveModalDto: InfoModalDto<T>) {
        archiveModalDto.isLinkTextVisible = false;
        archiveModalDto.isCancelButtonVisible = true;
        archiveModalDto.headerTitleClass = "fs-6 fw-boldest";
        archiveModalDto.headerIconSrc = "/assets/common/icons/archive-off-dark.svg";
        archiveModalDto.buttonClass = "btn btn-sm btn-success";
        archiveModalDto.buttonIconSrc = "/assets/common/icons/archive-off-white.svg";
        archiveModalDto.buttonText = this.l("Unarchive");
        archiveModalDto.buttonTextClass = "text-white";
    }

    initializeRecycleModalDto<T>(recycleModalDto: InfoModalDto<T>) {
        recycleModalDto.isLinkTextVisible = true;
        recycleModalDto.linkText = this.l("ArchiveInstead");
        recycleModalDto.isCancelButtonVisible = true;
        recycleModalDto.headerTitleClass = "fs-6 fw-boldest";
        recycleModalDto.headerIconSrc = "/assets/common/icons/recycle-dark.svg";
        recycleModalDto.buttonClass = "btn btn-sm btn-success";
        recycleModalDto.buttonIconSrc = "/assets/common/icons/recycle-white.svg";
        recycleModalDto.buttonText = this.l("Recycle");
        recycleModalDto.buttonTextClass = "text-white";
    }

    initializeDeleteModalDto<T>(deleteModalDto: InfoModalDto<T>) {
        deleteModalDto.isLinkTextVisible = false;
        deleteModalDto.isCancelButtonVisible = true;
        deleteModalDto.headerTitleClass = "fs-6 fw-boldest";
        deleteModalDto.headerIconSrc = "/assets/common/icons/trash-dark.svg";
        deleteModalDto.buttonClass = "btn btn-sm btn-error";
        deleteModalDto.buttonIconSrc = "/assets/common/icons/trash-white.svg";
        deleteModalDto.buttonText = this.l("Delete");
        deleteModalDto.buttonTextClass = "text-white";
    }

    initializeDeleteFromStorageModalDto<T>(deleteFromStorageModalDto: InfoModalDto<T>) {
        deleteFromStorageModalDto.isLinkTextVisible = true;
        deleteFromStorageModalDto.linkText = this.l("ArchiveInstead");
        deleteFromStorageModalDto.isCancelButtonVisible = true;
        deleteFromStorageModalDto.headerTitleClass = "fs-6 fw-boldest";
        deleteFromStorageModalDto.headerIconSrc = "/assets/common/icons/trash-dark.svg";
        deleteFromStorageModalDto.buttonClass = "btn btn-sm btn-error";
        deleteFromStorageModalDto.buttonIconSrc = "/assets/common/icons/trash-white.svg";
        deleteFromStorageModalDto.buttonText = this.l("Delete");
        deleteFromStorageModalDto.buttonTextClass = "text-white";
    }

    // Helper function to replace specific words in widget names and descriptions
    // replaceWidgetName takes in a widget object, a target string, and a replacement string.
    // - If the widget has an editWidgetConfig with a general name field that includes the target string,
    //   it replaces all occurrences of the target with the replacement in the general name, description, and name fields.
    // - If the editWidgetConfig does not exist but the widget's name includes the target string,
    //   it performs the replacement in the widget's name and description fields.
    replaceWidgetName(widget: any, target: string, replacement: string) {
        if (widget.editWidgetConfig?.general?.name?.includes(target)) {
            widget.editWidgetConfig.general.name = widget.editWidgetConfig.general.name.replace(target, replacement);
            widget.editWidgetConfig.general.description = widget.editWidgetConfig.general.description.replace(
                target,
                replacement
            );
            widget.description = widget.description.replace(target, replacement);
            widget.name = widget.name.replace(target, replacement);
        } else if (widget.name?.includes(target) && widget.description) {
            widget.name = widget.name.replace(target, replacement);
            widget.description = widget.description.replace(target, replacement);
        }
    }

    // General success notification
    showSuccessNotify(message: string, title?: string): void {
        this.toastr.success(message);
    }

    // General error notification
    showErrorNotify(message: string, title?: string): void {
        this.toastr.error(message);
    }

    // General info notification
    showInfoNotify(message: string, title?: string): void {
        this.toastr.info(message);
    }

    // General warning notification
    showWarningNotify(message: string, title?: string): void {
        this.toastr.warning(message);
    }
}
