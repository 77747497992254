<div class="bottom-draweru58-data-x">
    <div class="frame-43">
        <div class="stack-3">
            <div class="frame-145">
                <div class="widget-data-grid-dense" >
                    <div class="data-grid-head data-grid">
                        <div class="table-cell">
                            <div class="stack">
                                <div class="th-text">X AXIS</div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="box-list"
                        cdkDropList
                        cdkDropListOrientation="vertical"
                        (cdkDropListDropped)="dragDrop($event)">
                        <div class="drag-box " *ngFor="let column of xAxiscolumns" cdkDrag>
                            <div
                                [class]="this.xAxisActiveId === column.id ? 'data-grid-row data-grid':'data-grid-row-1 data-grid-row-4'"
                                (mousedown)="seriesClickXaxis(column.id)" (click)="seriesClickXaxis(column.id)">
                                <div class="frame-155">
                                    <div
                                        [class]="this.xAxisActiveId === column.id ? 'name2-1 publicsans-semi-bold-pale-sky-14px':'name2 publicsans-semi-bold-pale-sky-14px'">{{column.name}}</div>
                                </div>
                                <div class="table-cell-2 table-cell-3">
                                    <div class="icon-button" *ngIf="yAxiscolumns.length>0"
                                         (click)="newdataModal.show(xAxiscolumns,xAxis,activeColumn.id)">
                                        <div>
                                            <img
                                                [class]="this.xAxisActiveId === column.id ? 'iconsic_trash-active-1':'iconsic_trash'"
                                                src="/assets/common/images/edit-widget/data/database-edit.svg"
                                                alt="icons/ic_trash">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="widget-data-grid-dense">
                    <div class="data-grid-head data-grid">
                        <div class="table-cell">
                            <div class="stack">
                                <div class="th-text">Y AXIS</div>
                            </div>
                        </div>
                    </div>
                    <div
                        style="overflow-x: scroll !important; height: 147px !important;"
                        class="box-list"
                        cdkDropList
                        cdkDropListOrientation="vertical"
                        (cdkDropListDropped)="dragDrop($event)">
                        <div class="drag-box " *ngFor="let column of yAxiscolumns" cdkDrag>
                            <div (click)="seriesClick(column.id)"
                                 [class]="this.activeId === column.id ? 'data-grid-row data-grid':'data-grid-row-1 data-grid-row-4'">
                                <div class="frame-155">
                                    <div
                                        [class]="this.activeId === column.id ? 'name2-1 publicsans-semi-bold-pale-sky-14px':'name2 publicsans-semi-bold-pale-sky-14px'">{{column.name}}</div>
                                </div>
                                <div class="table-cell-2 table-cell-3">
                                    <div class="icon-button" (click)="removeColumn(column.id)">
                                        <img
                                            [class]="this.activeId === column.id ? 'iconsic_trash-active':'iconsic_trash'"
                                            src="/assets/common/images/edit-widget/data/icons-ic-trash.svg"
                                            alt="icons/ic_trash">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="frame-49">
                        <div class="button-2 button-4" (click)="newdataModal.show(yAxiscolumns,yAxis,-1)">
                            <div class="label-3 label-5">New data</div>
                            <img class="end-icon" src="/assets/common/images/edit-widget/data/end-icon-2.svg"
                                 alt="end icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-account-update-user-form">
                <div class="title-icon">
                    <div class="icon-button-3">
                        <div class="ic_tabler_adjustments-alt"></div>
                    </div>
                    <div class="title publicsans-bold-charade-14px">GENERAL</div>
                </div>
                <div class="frame-161">
                    <div class="frame-160">
                        <div class="stack-2">
                            <div class="text-field">
                                <div class="input">
                                    <div class="container">
                                        <div class="label-focus label-5 publicsans-normal-manatee-12px">Name</div>
                                        <div class="content">
                                            <input type="text" class="name-input publicsans-normal-charade-16px"
                                                   [disabled]="!(this.xAxisActiveId !=-1 || this.activeId !=-1)"
                                                   [(ngModel)]="activeColumn.name" (ngModelChange)="namehange()">
                                        </div>
                                    </div>
                                    <div class="space-s"></div>
                                </div>
                            </div>
                        </div>
                        <div class="stack-2" *ngIf="activeColumn.type == yAxis || activeColumn.id>0">
                            <div class="text-field-2 text-field-3">
                                <div class="input">
                                    <div class="container">
                                        <div class="label-focus label-5 publicsans-normal-manatee-12px">Aggregate
                                        </div>
                                        <div class="content">
                                            <select class="custom-select-1 form-control"
                                                    [(ngModel)]="activeColumn.aggregate"
                                                    (ngModelChange)="aggregateChange()" [disabled]="this.legendEnable">
                                                <option value="None">None</option>
                                                <option value="Sum">Sum</option>
                                                <option value="Avg">Avg</option>
                                                <option value="Max">Max</option>
                                                <option value="Min">Min</option>
                                                <option value="First">First</option>
                                                <option value="Last">Last</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-field-2 text-field-3">
                                <div class="input">
                                    <div class="container">
                                        <div class="label-focus label-5 publicsans-normal-manatee-12px">Unit
                                        </div>
                                        <div class="content">
                                            <select class="custom-select-1 form-control" [(ngModel)]="activeColumn.unit"
                                                    (ngModelChange)="unitChange()">
                                                <option *ngFor="let measurementUnit of measurementUnits"
                                                        value="{{ measurementUnit.measurementUnitFk?.value??'None' }}">
                                                    {{ measurementUnit.measurementUnitFk?.name ?? 'None' }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-field-2 text-field-3">
                                <div class="input">
                                    <div class="container">
                                        <div class="label-focus label-5 publicsans-normal-manatee-12px">Frequency
                                        </div>
                                        <div class="content">
                                            <select class="custom-select-1 form-control"
                                                    [(ngModel)]="activeColumn.timeFrequency"
                                                    (ngModelChange)="frequencyChange()">
                                                <option value="10">10 min</option>
                                                <option value="Hour">1 Hour</option>
                                                <option value="Day">1 Day</option>
                                                <option value="Month">1 Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-account-update-user-form-1">
                <div class="title-icon"  *ngIf="activeColumn.type == yAxis || activeColumn.id>0">
                    <div class="icon-button-3">
                        <div class="ic_tabler_palette"></div>
                    </div>
                    <div class="title publicsans-bold-charade-14px">VISUALS</div>
                </div>
                <div class="frame-159">
                    <div class="frame-15"  *ngIf="activeId != -1">
                        <div class="title-1">CHART TYPE</div>
                        <div class="toggle-button-group toggle-button">
                            <article
                                [class]="this.activeColumn.chartType=='line'?'toggle-button-standalone-1':'toggle-button-standalone'"
                                (click)="typeChange('line')">
                                <div class="ic_tabler_chart-line ic_tabler_chart"></div>
                            </article>
                            <article
                                [class]="this.activeColumn.chartType=='column'?'toggle-button-standalone-1':'toggle-button-standalone'"
                                (click)="typeChange('column')">
                                <div class="ic_tabler_chart-bar ic_tabler_chart"></div>
                            </article>
                            <article
                                [class]="this.activeColumn.chartType=='area'?'toggle-button-standalone-1':'toggle-button-standalone'"
                                (click)="typeChange('area')">
                                <div class="ic_tabler_chart-area ic_tabler_chart"></div>
                            </article>
                            <article
                                [class]="this.activeColumn.chartType=='scatter'?'toggle-button-standalone-1':'toggle-button-standalone'"
                                (click)="typeChange('scatter')">
                                <div class="ic_tabler_chart-dots ic_tabler_chart"></div>
                            </article>
                        </div>


                        <div class="switch switch-5">
                            <div class="switch">
                                <label class="toggle-switch">
                                    <input type="checkbox"
                                           [ngModel]="multiScale"
                                           (change)="changeMultiScale($event)"
                                    >
                                    <div class="f-slider"></div>
                                </label>
                                <div class="label-end publicsans-normal-charade-14px">Multi Scale</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title-icon">
                    <div class="icon-button-3">
                        <div class="ic_tabler_palette"></div>
                    </div>
                    <div class="title publicsans-bold-charade-14px">LEGEND</div>
                </div>
                <div class="frame-159">
                    <div class="frame-15">
                        <div class="switch switch-5">
                            <div class="switch">
                                <label class="toggle-switch" style="display: flex;flex: 0 0 auto;">
                                    <input type="checkbox"
                                           [ngModel]="legendEnable"
                                           (change)="changeLegend($event)"
                                    >
                                    <div class="f-slider"></div>
                                </label>
                                <div class="label-end publicsans-normal-charade-14px">Enable</div>
                                <div class="input" style="margin-left: 40px; width: auto; bottom: 2px;"
                                     *ngIf="legendEnable">
                                    <div class="container">
                                        <div class="content">
                                            <select class="custom-select-1 form-control"
                                                    [(ngModel)]="legend"
                                                    (ngModelChange)="legendChange()"
                                            >
                                                <option value="None">Please select</option>
                                                <option value="Month">Month</option>
                                                <option value="Year">Year</option>
                                                <option value="Model" *ngIf="xAxiscolumns[0].id!=0">Model</option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div class="input" style="width: auto; bottom: 2px;"
                                     *ngIf="legendEnable && (this.legend == 'Year' || this.legend == 'Month')">
                                    <div class="container">
                                        <div class="content">
                                            <p-multiSelect [options]="years" [(ngModel)]="selectedYears"
                                                           (onPanelHide)="changedYear()"
                                                           [filter]="true" optionLabel="name"
                                                           placeholder="Years"></p-multiSelect>

                                        </div>
                                    </div>
                                </div>
                                <div class="input" style="width: auto; bottom: 2px;"
                                     *ngIf="legendEnable && (this.legend == 'Month') && isSelectedYears">
                                    <div class="container">
                                        <div class="content">

                                            <p-multiSelect [options]="months" [(ngModel)]="selectedMonths"
                                                           (onPanelHide)="changedMonth()"
                                                           [filter]="true" optionLabel="name"
                                                           placeholder="Months"></p-multiSelect>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<newdataModal #newdataModal (addData)="addData($event)" [powerPlant]="powerPlant"></newdataModal>
