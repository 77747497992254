import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";

import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";
import {
    DashboardMode,
    EditWidgetConfig,
    PowerPlant,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";
import { Store } from "@node_modules/@ngrx/store";
import { AppComponentBase } from "@shared/common/app-component-base";
import * as _ from "lodash";
import { forEach as _forEach } from "lodash-es";
import { EditWidgetConfigDto } from "@app/state-management/models/edit-widget-config";
import { SubHeaderProps } from "@app/shared/models/dash-sub-header.model";
import { EditGeneralComponent } from "./general/edit-general.component";
import { EditDatasourceComponent } from "@app/shared/common/edit-widget/data-source/edit-datasource.component";
import { EditSeriesComponent } from "@app/shared/common/edit-widget/series/edit-series.component";
import { EditFormatComponent } from "@app/shared/common/edit-widget/format/edit-format.component";
import { EditAxesComponent } from "@app/shared/common/edit-widget/axes/edit-axes.component";
import { EditSettingsComponent } from "@app/shared/common/edit-widget/settings/settings.component";
import { EditDataComponent } from "@app/shared/common/edit-widget/data/data.component";
import { EditUnitModelComponent } from "@app/shared/common/edit-widget/unit-model/unit-model.component";

@Component({
    selector: "app-edit-widget-layout",
    templateUrl: "./edit-widget-layout.component.html",
    styleUrls: ["./edit-widget-layout.component.css"],
    encapsulation: ViewEncapsulation.None
})
export class EditWidgetLayoutComponent extends AppComponentBase implements OnInit {
    @ViewChild("general", { static: false }) general: EditGeneralComponent;
    @ViewChild("datasource", { static: false }) datasource: EditDatasourceComponent;
    @ViewChild("series", { static: false }) series: EditSeriesComponent;
    @ViewChild("format", { static: false }) format: EditFormatComponent;
    @ViewChild("axes", { static: false }) axes: EditAxesComponent;
    @ViewChild("data", { static: false }) data: EditDataComponent;
    @ViewChild("settings", { static: false }) settings: EditSettingsComponent;
    @ViewChild("unitModel", { static: false }) unitModel: EditUnitModelComponent;
    @Input() powerPlant: PowerPlant;
    @Output() saveDashboardNotConfirm: EventEmitter<any> = new EventEmitter<any>();
    @Input() props: SubHeaderProps;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

    config: EditWidgetConfigDto;

    tabIndex: number = -1;
    _tabs: { id: number; icon: string; title: string; type: string; visible: boolean }[] = [
        {
            id: 0,
            icon: "/assets/common/images/edit-widget/icon.svg",
            title: "General",
            type: "General",
            visible: false
        },
        {
            id: 1,
            icon: "/assets/common/images/edit-widget/icon-1.svg",
            title: "Datasource",
            type: "Datasource",
            visible: false
        },
        {
            id: 2,
            icon: "/assets/common/images/edit-widget/icons-ic-notifications.svg",
            title: "Series",
            type: "Series",
            visible: false
        },
        {
            id: 3,
            icon: "/assets/common/images/edit-widget/icons-ic-share.svg",
            title: "Format",
            type: "Format",
            visible: false
        },
        {
            id: 4,
            icon: "/assets/common/images/edit-widget/icons-ic-vpn-key.svg",
            title: "Axes",
            type: "Axes",
            visible: false
        },
        {
            id: 5,
            icon: "/assets/common/images/edit-widget/edit-data.svg",
            title: "Data",
            type: "Data",
            visible: false
        },
        {
            id: 6,
            icon: "/assets/common/images/edit-widget/edit-settings.svg",
            title: "Settings",
            type: "Settings",
            visible: false
        },
        {
            id: 7,
            icon: "/assets/common/images/edit-widget/edit-settings.svg",
            title: "UnitModel",
            type: "UnitModel",
            visible: false
        }
    ];
    tabs: { id: number; icon: string; title: string; type: string; visible: boolean }[];
    active: boolean = false;
    buttonSavesActiveIds = [1049, 57, 1052, 1053];
    protected readonly DashboardMode = DashboardMode;

    constructor(injector: Injector, private _reportDashboardServiceProxy: ReportDashboardServiceProxy, private store: Store) {
        super(injector);
    }

    ngOnInit() {
        this.active = false;
        this.store
            .select((state) => state)
            .subscribe((state: any) => {
                if (!_.isNil(state.editWidget.data.general)) {
                    this.tabs = [...this._tabs];
                    this.config = state.editWidget.data;

                    _forEach(this.tabs, (ou) => {
                        if (ou.type == "General") {
                            ou.visible = this.config.general.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "Datasource") {
                            ou.visible = this.config.datasource.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "Series") {
                            ou.visible = this.config.series.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "Format") {
                            ou.visible = this.config.format.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "Axes") {
                            ou.visible = this.config.axex.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "Data") {
                            ou.visible = this.config.data?.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "Settings") {
                            ou.visible = this.config.settings?.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        } else if (ou.type == "UnitModel") {
                            ou.visible = this.config.unitModel?.visible;
                            if (!this.active) {
                                if (ou.visible) {
                                    this.active = true;
                                    this.tabIndex = ou.id;
                                }
                            }
                        }
                    });

                    this.tabs = [...this.tabs.filter((x) => x.visible)];
                }
            });
    }

    public ShowOverlay(overlayName, animationName) {
        this.active = false;
        ReportTrialsUtilities.ShowOverlay(overlayName, animationName);
    }

    public HideOverlay(overlayName, animationName) {
        this.active = false;
        ReportTrialsUtilities.HideOverlay(overlayName, animationName);
    }

    public Cancel(overlayName, animationName) {
        this.active = false;
        ReportTrialsUtilities.HideOverlay(overlayName, animationName);
        if (this.props.dashboard.mode == DashboardMode.DataAnalysis) {
            this.cancel.emit();
        }
        abp.event.trigger("app-edit-widget-layout", false);

    }

    public setActiveTab(index: number) {
        this.tabIndex = index;
    }

    SaveWidget(overlayName, animationName) {
        // ReportTrialsUtilities.HideOverlay(overlayName, animationName);

        let config = JSON.stringify(this.config);
        let jsonObj = JSON.parse(config); // string to "any" object first
        let editWidgetConfig = jsonObj as EditWidgetConfig;
        if (editWidgetConfig.general.name == "") {
            this.message.error("", this.l("You need to change the name of the widget"));
        } else {
            if (editWidgetConfig.general.name != "Time Series Analysis") {
                //@ts-ignore
                if (editWidgetConfig.component == "app_custom_gadget") {
                    if (editWidgetConfig.series.selectedComponent.length == 0) {
                        this.message.error("", this.l("Need to add base component"));
                        return;
                    }
                }
                let w = 0;
                let h = 0;
                //@ts-ignore
                if (editWidgetConfig.component == "app_text_gadget") {
                    //@ts-ignore
                    let divId = editWidgetConfig.divId;
                    w = Number((<HTMLInputElement>document.getElementById(divId)).parentElement.parentElement.getAttribute("gs-w"));
                    h = Number((<HTMLInputElement>document.getElementById(divId)).parentElement.parentElement.getAttribute("gs-h"));
                }
                this.message.confirm("", this.l("AreYouSure"), (isConfirmed) => {
                    if (isConfirmed) {
                        this._reportDashboardServiceProxy
                            .saveWidgetInfo(this.config.widgetId, this.powerPlant.type as number, false, w, h, editWidgetConfig)
                            .subscribe(() => {
                                this.active = false;
                                this.saveDashboardNotConfirm.emit(false);
                                ReportTrialsUtilities.HideOverlay(overlayName, animationName);

                                // this.showInfoNotify(this.l("SavedSuccessfully"));
                                this.showInfoNotify(this.l("SavedSuccessfully"));
                            });
                    }
                });
            } else {
                this.message.error("", this.l("You need to change the name of the widget"));
            }
        }


    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
