import { Component, EventEmitter, Injector, Input, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalDirective } from "@node_modules/ngx-bootstrap/modal";
import {
    PowerPlant,
    PowerPlantUnitDto,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";
import { Store } from "@node_modules/@ngrx/store";
import { DataWidgetConfigContent } from "@app/shared/models/DataWidgetConfigDto";

@Component({
    selector: "newdataModal",
    templateUrl: "./new-data.component.html",
    styleUrls: ["./new-data.component.scss"]
})
export class NewDataComponent extends AppComponentBase {
    @Input() powerPlant: PowerPlant;
    @ViewChild("createOrEditModal", { static: true }) modal: ModalDirective;
    @Output() addData: EventEmitter<any> = new EventEmitter<any>();
    powerPlantUnitList: PowerPlantUnitDto[];
    selectedTurbine: PowerPlantUnitDto;
    turbineName: string = "";
    baseComponents: DataWidgetConfigContent[] = [];
    selectedBaseComponents: DataWidgetConfigContent[] = [];
    baseComponent: DataWidgetConfigContent[] = [];
    filterText = "";
    type = "";
    activeId = 0;
    selectedPowerPlantList: PowerPlant[] = [];
    selectedUnitList: PowerPlantUnitDto[] = [];
    axiscolumns: DataWidgetConfigContent[] = [];
    disabled = false;

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private store: Store
    ) {
        super(injector);
    }

    show(Axiscolumns: DataWidgetConfigContent[], type: string, id: number): void {
        this.disabled = false;
        this.selectedBaseComponents = [];
        if (type == "yAxis") {
            this.selectedBaseComponents = this.selectedBaseComponents.concat(Axiscolumns);
            if (this.selectedBaseComponents.length < 10)
                this.disabled = false;
            else
                this.disabled = true;
        }
        this.axiscolumns = Axiscolumns;
        this.activeId = id;
        this.filterText = "";
        this.baseComponents = [];
        this.type = "";
        this.modal.show();
        this.store.select((state) => state).subscribe((state: any) => {
            if (state.subHeader.data.dashboardName) {
                this.powerPlantUnitList = state.subHeader.data.powerPlantUnitList;
                this.turbineName = state.subHeader.data.turbineName;
                if (this.powerPlantUnitList)
                    this.selectedTurbine = this.powerPlantUnitList.find(x => x.name == this.turbineName);

            }

            if (state.subHeader.data.selectedPlantList) {
                if ((state.subHeader.data.selectedPlantList).length > 0) {
                    this.selectedPowerPlantList = state.subHeader.data.selectedPlantList;
                    this.selectedUnitList = state.subHeader.data.selectedPowerPlantUnitList;
                }
            }
            if (state.subHeader.data.selectedPowerPlantUnitList) {
                if ((state.subHeader.data.selectedPowerPlantUnitList).length > 0) {
                    this.selectedUnitList = state.subHeader.data.selectedPowerPlantUnitList;
                }
            }

            if (state.subHeader.data.selectedPowerPlantUnitList || state.subHeader.data.selectedPowerPlantUnitList) {
                this.getBaseComponent();
            }
        });

        this.type = type;
    }

    close(): void {
        this.modal.hide();
        this.baseComponents = [];
        this.disabled = false;
    }

    changeSelection(item) {
        if (item.isSelected) {
            if (this.selectedBaseComponents.length > 0) {
                let control = this.selectedBaseComponents.find(x => x.id == item.id);
                if (control != null) {
                    let indexNumber = this.selectedBaseComponents.indexOf(item);
                    this.selectedBaseComponents.splice(indexNumber, 1);
                }
            }
        } else {
            if (this.selectedBaseComponents.length < 10) {
                if (this.selectedBaseComponents.length > 0) {
                    let control = this.selectedBaseComponents.find(x => x.id == item.id);
                    if (control == null) {
                        this.selectedBaseComponents.push(item);
                    }
                } else {
                    this.selectedBaseComponents.push(item);
                }
            }
        }

        if (this.baseComponents.filter(x => x.isSelected == true).length < 10) {
            this.baseComponents.forEach(val => {
                if (val.id == item.id) val.isSelected = !val.isSelected;
                else {
                    if (this.type == "xAxis")
                        val.isSelected = false;
                }
            });

            if (this.baseComponents.filter(x => x.isSelected == true).length < 10)
                this.disabled = false;
            else
                this.disabled = true;
        } else
            this.disabled = true;
    }

    getBaseComponent() {
        let powerPlantIds = this.selectedPowerPlantList.map(x => x.id);
        let unitIds = this.selectedUnitList.map(x => x.id);
        this.primengTableHelper.showLoadingIndicator();
        this._reportDashboardServiceProxy.getActiveUnitBaseComponents(this.filterText, powerPlantIds, unitIds)
            .subscribe((baseComponents: any) => {

                this.baseComponents = [];
                for (let item of baseComponents) {
                    let isSelected = false;
                    if (item.id == this.activeId)
                        isSelected = true;
                    else {
                        if (this.axiscolumns.filter(x => x.id == item.id).length > 0)
                            isSelected = true;
                        else
                            isSelected = false;
                    }

                    if (this.selectedBaseComponents.length > 0) {
                        let control = this.selectedBaseComponents.find(x => x.id == item.id);
                        if (control != null) {
                            isSelected = true;
                        }
                    }
                    this.baseComponents.push({
                        id: item.id,
                        uuid: item.uuid,
                        // isSelected: item.id == this.activeId ? true : false,
                        isSelected: isSelected,
                        name: item.name,
                        type: this.type,
                        dataSpecs: [],
                        timeFrequency: "10",
                        aggregate: "None",
                        unit: item.unit,
                        index: -1,
                        chartType: "line"
                    });
                }
                if (this.type == "xAxis") {
                    this.baseComponents.push({
                        id: 0,
                        uuid: "0", name: "Time",
                        type: "xAxis",
                        isSelected: this.activeId == 0 ? true : false,
                        dataSpecs: [],
                        timeFrequency: "10",
                        aggregate: "",
                        unit: "",
                        index: -1,
                        chartType: "line"
                    });
                }
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    addDataAxis() {
        if (this.selectedBaseComponents) {
            this.addData.emit(this.selectedBaseComponents);
            this.modal.hide();
        }
    }

    clearSearch() {
        this.filterText = "";
        this.getBaseComponent();
    }


}
