import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppLocalizationService } from "@app/shared/common/localization/app-localization.service";
import { AppNavigationService } from "@app/shared/layout/nav/app-navigation.service";
import { IoTAppCommonModule } from "@shared/common/common.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { AppAuthService } from "./auth/app-auth.service";
import { AppRouteGuard } from "./auth/auth-route-guard";
import { DataGridComponent } from "./data-grid/data-grid.component";
import { CommonLookupModalComponent } from "./lookup/common-lookup-modal.component";
import { EntityTypeHistoryModalComponent } from "./entityHistory/entity-type-history-modal.component";
import { EntityChangeDetailModalComponent } from "./entityHistory/entity-change-detail-modal.component";
import { DateRangePickerInitialValueSetterDirective } from "./timing/date-range-picker-initial-value.directive";
import { DatePickerInitialValueSetterDirective } from "./timing/date-picker-initial-value.directive";
import { DateTimeService } from "./timing/date-time.service";
import { TimeZoneComboComponent } from "./timing/timezone-combo.component";
import { NgxBootstrapDatePickerConfigService } from "assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { CountoModule } from "angular2-counto";
import { AppBsModalModule } from "@shared/common/appBsModal/app-bs-modal.module";
import { SingleLineStringInputTypeComponent } from "./input-types/single-line-string-input-type/single-line-string-input-type.component";
import { ComboboxInputTypeComponent } from "./input-types/combobox-input-type/combobox-input-type.component";
import { CheckboxInputTypeComponent } from "./input-types/checkbox-input-type/checkbox-input-type.component";
import { MultipleSelectComboboxInputTypeComponent } from "./input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PasswordInputWithShowButtonComponent } from "./password-input-with-show-button/password-input-with-show-button.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { KeyValueListManagerComponent } from "./key-value-list-manager/key-value-list-manager.component";
import { EditMenuComponent } from "@app/shared/common/edit-menu/edit-menu.component";
import { SolidgaugeChartComponent } from "./dashboard-charts/solidgauge-chart/solidgauge-chart.component";
import { BulletgraphChartComponent } from "./dashboard-charts/bulletgraph-chart/bulletgraph-chart.component";
import { GaugeseriesChartComponent } from "./dashboard-charts/gaugeseries-chart/gaugeseries-chart.component";
import { WindRoseChartComponent } from "./dashboard-charts/wind-rose-chart/wind-rose-chart.component";
import { StackedColumnChartComponent } from "@app/shared/common/dashboard-charts/stacked-column-chart/stacked-column-chart.component";
import { ScatterPlotChartComponent } from "./dashboard-charts/scatter-plot-chart/scatter-plot-chart.component";
import { HistogramChartComponent } from "./dashboard-charts/histogram-chart/histogram-chart.component";
import { LineChartComponent } from "./dashboard-charts/line-chart/line-chart.component";
import { CreateDashboardComponent } from "@app/shared/common/dashboard-trials/create-dashboard/create-dashboard.component";
import { DeleteDashboardComponent } from "@app/shared/common/dashboard-trials/delete-dashboard/delete-dashboard.component";
import { PerDeleteDashboardComponent } from "@app/shared/common/dashboard-trials/per-delete-dashboard/per-delete-dashboard.component";
import { ShareDashboardComponent } from "@app/shared/common/dashboard-trials/share-dashboard/share-dashboard.component";
import { EditWidgetLayoutComponent } from "@app/shared/common/edit-widget/edit-widget-layout.component";
import { EditSeriesComponent } from "@app/shared/common/edit-widget/series/edit-series.component";
import { EditGeneralComponent } from "@app/shared/common/edit-widget/general/edit-general.component";
import { EditFormatComponent } from "@app/shared/common/edit-widget/format/edit-format.component";
import { EditDatasourceComponent } from "@app/shared/common/edit-widget/data-source/edit-datasource.component";
import { EditAxesComponent } from "@app/shared/common/edit-widget/axes/edit-axes.component";
import { EditDataComponent } from "./edit-widget/data/data.component";
import { EditSettingsComponent } from "./edit-widget/settings/settings.component";
import { EditUnitModelComponent } from "@app/shared/common/edit-widget/unit-model/unit-model.component";
import { NewDataComponent } from "./edit-widget/data/new-data/new-data.component";
import { MapService } from "@app/shared/services/map.service";
import { CircleProgressComponent } from "@app/shared/common/circle-progress/circle-progress.component";
import { MultiSelectModule } from "@node_modules/primeng/multiselect";
import { SpinnerComponent } from "./spinner/spinner.component";
import { PlantDetailDrawerComponent } from "@app/shared/common/map/plant-detail-drawer/plant-detail-drawer.component";
import { CompanyDrawerComponent } from "@app/shared/common/map/company-drawer/company-drawer.component";
import { XRangeChartComponent } from "@app/shared/common/dashboard-charts/xrange-chart/xrange-chart.component";
import { InfoModalComponent } from "@app/shared/common/info-modal/info-modal.component";
import { DateRangePickerComponent } from "@app/shared/common/forms/date-range-picker/date-range-picker.component";
import { PowerPlantSelectionComponent } from "@app/shared/common/forms/power-plant-selection/power-plant-selection.component";
import { PowerPlantUnitSelectionComponent } from "@app/shared/common/forms/power-plant-unit-selection/power-plant-unit-selection.component";
import { BubbleChartComponent } from "./dashboard-charts/bubble-chart/bubble-chart.component";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";
import { SaveWidgetComponent } from "./dashboard-trials/save-widget/save-widget.component";
import { TableOfContentComponent } from "@app/shared/common/report/table-of-content/toc-content/table-of-content.component";
import { TocOverviewComponent } from "@app/shared/common/report/table-of-content/toc-overview/toc-overview.component";
import { SlideToggleThemeComponent } from "./forms/slide-toggle-theme/slide-toggle-theme.component";
import { CheckboxThemeComponent } from "./forms/checkbox-theme/checkbox-theme.component";
import { HighlightTextDirective } from "./directives/highlight-text.directive";
import { PerDeleteReportComponent } from "@app/shared/common/report/per-delete-dashboard/per-delete-report.component";
import { RangeSliderComponent } from "@app/shared/common/range-slider/range-slider.component";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { NgxPaginationModule } from "@node_modules/ngx-pagination";
import { TableOptionComponent } from "./table-option/table-option.component";
import { StepperComponent } from "@app/shared/common/stepper/stepper.component";
import { HeatmapChartComponent } from "./dashboard-charts/heatmap-chart/heatmap-chart.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DefaultHeatmapChartComponent } from "./dashboard-charts/default-heatmap-chart/default-heatmap-chart.component";
import { CountsOfSevenDaysChartComponent } from "@app/shared/common/counts-of-last-seven-days-chart/counts-of-seven-days-chart.component";
import { DowntimeChartService } from "@app/main/report-trials/charts/downtimes/downtime-chart.service";
import { PowerCapacityFactorChartService } from "@app/main/report-trials/charts/power-capacity-factor-charts/power-capacity-factor-chart.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        IoTAppCommonModule,
        TableModule,
        PaginatorModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        MultiSelectModule,
        NgxPaginationModule,
        DragDropModule,
    ],
    declarations: [
        DataGridComponent,
        EditMenuComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        ProgressBarComponent,
        KeyValueListManagerComponent,
        SolidgaugeChartComponent,
        BulletgraphChartComponent,
        GaugeseriesChartComponent,
        WindRoseChartComponent,
        StackedColumnChartComponent,
        ScatterPlotChartComponent,
        HistogramChartComponent,
        LineChartComponent,
        DeleteDashboardComponent,
        PerDeleteReportComponent,
        CreateDashboardComponent,
        PerDeleteDashboardComponent,
        ShareDashboardComponent,
        EditWidgetLayoutComponent,
        EditSeriesComponent,
        EditGeneralComponent,
        EditFormatComponent,
        EditDatasourceComponent,
        EditAxesComponent,
        EditSettingsComponent,
        EditUnitModelComponent,
        EditDataComponent,
        NewDataComponent,
        CompanyDrawerComponent,
        CircleProgressComponent,
        SpinnerComponent,
        PlantDetailDrawerComponent,
        XRangeChartComponent,
        InfoModalComponent,
        DateRangePickerComponent,
        PowerPlantSelectionComponent,
        PowerPlantUnitSelectionComponent,
        TableOfContentComponent,
        TocOverviewComponent,
        BubbleChartComponent,
        SaveWidgetComponent,
        SlideToggleThemeComponent,
        CheckboxThemeComponent,
        HighlightTextDirective,
        RangeSliderComponent,
        TruncatePipe,
        TableOptionComponent,
        StepperComponent,
        HeatmapChartComponent,
        DefaultHeatmapChartComponent,
        CountsOfSevenDaysChartComponent,
    ],
    exports: [
        DataGridComponent,
        EditMenuComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        PasswordInputWithShowButtonComponent,
        ProgressBarComponent,
        KeyValueListManagerComponent,
        SolidgaugeChartComponent,
        BulletgraphChartComponent,
        GaugeseriesChartComponent,
        SolidgaugeChartComponent,
        WindRoseChartComponent,
        StackedColumnChartComponent,
        ScatterPlotChartComponent,
        HistogramChartComponent,
        LineChartComponent,
        DeleteDashboardComponent,
        PerDeleteReportComponent,
        CreateDashboardComponent,
        PerDeleteDashboardComponent,
        ShareDashboardComponent,
        EditWidgetLayoutComponent,
        EditSeriesComponent,
        EditGeneralComponent,
        EditFormatComponent,
        EditDatasourceComponent,
        EditAxesComponent,
        EditSettingsComponent,
        EditUnitModelComponent,
        EditDataComponent,
        CompanyDrawerComponent,
        CircleProgressComponent,
        SpinnerComponent,
        PlantDetailDrawerComponent,
        XRangeChartComponent,
        InfoModalComponent,
        DateRangePickerComponent,
        PowerPlantSelectionComponent,
        PowerPlantUnitSelectionComponent,
        BubbleChartComponent,
        TableOfContentComponent,
        TocOverviewComponent,
        SaveWidgetComponent,
        SlideToggleThemeComponent,
        CheckboxThemeComponent,
        HighlightTextDirective,
        RangeSliderComponent,
        TruncatePipe,
        StepperComponent,
        HeatmapChartComponent,
        DefaultHeatmapChartComponent,
        CountsOfSevenDaysChartComponent,
        DragDropModule,
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
        MapService,
        ChartService,
    ],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
