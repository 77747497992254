import { DateTime } from "luxon";
import {
    DashboardToTurbinesDto,
    GetReportDashboardForViewDto,
    ModelDto,
    PowerPlantType,
    PowerPlantUnitDto,
    WidgetInfoDto,
} from "@shared/service-proxies/service-proxies";

export interface ReportSection {
    id: number;
    index: number;
    title?: string;
    isRoot: boolean;
    rootId?: number;
    childOrder?: number;
    rootOrder?: number;
    isHidden?: boolean;
    active: boolean;
    header?: string;
    isHeaderActive?: boolean;
    childId?: number;
    twoColumnLayout?: boolean;
    components?: ReportComponent[];
    isTOCSelected?: boolean;
    hasIncompatibleWidget?: boolean;
    children?: ReportSection[]; // Add children property
}

export interface ReportComponent {
    showSubText?: boolean;
    subText?: string;
    id?: string;
    order?: number;
    type?: ReportSectionType;
    TextContent?: string;
    active?: boolean;
    imgUrl?: string;
    widgetTypeName?: string;
    widgetName?: string;
    customWidgetName?: string;
    dateString?: string;
    widgetId?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    powerPlantId?: number;
    dashboardToTurbines?: DashboardToTurbinesDto[];
    template?: "powerPlant" | "turbine" | string;
    selectedPowerPlantUnit?: PowerPlantUnitDto;
    selectedModel?: ModelDto;
    reportDashboardId?: number;
    isWidgetHeaderNameHideForPdf?: boolean;
    isWidgetDateTextHideForPdf?: boolean;
    temperatureBaseComponentId?: number;
}

export enum ReportSectionType {
    Text = "Text",
    Image = "Image",
    DataAnalysis = "Data Analysis",
    Widget = "Widget",
    TableOfContents = "Table Of Contents",
    Cover = "Cover",
}

export interface PowerPlanUnitFilters {
    id: number;
    key: number;
    value: string;
    selected: boolean;
}

export interface ModelFilter {
    id: number;
    name: string;
}

export interface WidgetAddModel {
    info?: WidgetInfoDto;
    dataAnalysis?: GetReportDashboardForViewDto;
    widgetStatus?: string;
}

export interface ReportGeneralSettings {
    targetAsset: {
        powerPlantType: PowerPlantType;
        powerPlantId: number;
        powerPlantUnitId: number;
    };
    dateRange: {
        startDate: string;
        endDate: string;
    };
    repeatEveryMonth?: boolean;
}

export const WidgetNamesToExclude = {
    PowerCurve: "Power Curve",
    HourlyPowerPlantAnalysis: "Hourly Power Plant Analysis",
    CapacityFactorGaugeSeries: "Capacity Factor - Gauge Series",
    CapacityFactorSolidGauge: "Capacity Factor - Solid Gauge",
    CapacityFactorBulletGraph: "Capacity Factor - Bullet Graph",
    ActivePowerGaugeSeries: "Active Power - Gauge Series",
    ActivePowerSolidGauge: "Active Power - Solid Gauge",
    ActivePowerBulletGraph: "Active Power - Bullet Graph",
    AvailabilityGaugeSeries: "Availability - Gauge Series",
    AvailabilitySolidGauge: "Availability - Solid Gauge",
    AvailabilityBulletGraph: "Availability - Bullet Graph",
    GenerationSolidGauge: "Generation - Solid Gauge",
    GenerationGaugeSeries: "Generation - Gauge Series",
    InverterGeneration: "Inverter Generation",
    SFCPFC: "SFC -PFC",
};

export const WidgetViewContentZoomNamesToExclude = ["String Status", "Inverter Generation", "Unit Summary"];


export const SingleItem400PxCharts = {
    CapacityFactorGaugeSeries: "Capacity Factor - Gauge Series",
    CapacityFactorSolidGauge: "Capacity Factor - Solid Gauge",
    CapacityFactorBulletGraph: "Capacity Factor - Bullet Graph",
    ActivePowerGaugeSeries: "Active Power - Gauge Series",
    ActivePowerSolidGauge: "Active Power - Solid Gauge",
    ActivePowerBulletGraph: "Active Power - Bullet Graph",
    AvailabilityGaugeSeries: "Availability - Gauge Series",
    AvailabilitySolidGauge: "Availability - Solid Gauge",
    AvailabilityBulletGraph: "Availability - Bullet Graph",
    GenerationSolidGauge: "Generation - Solid Gauge",
    GenerationGaugeSeries: "Generation - Gauge Series",
    WindSpeedWindDirectionAnalysis: "Wind Speed & Wind Direction Analysis",
};

export const TwoItem400PxTable = {
    UnitSummary: "Unit Summary",
    DowntimesTurbine: "Downtimes - Turbine",
    Top10Fault: "Top 10 Fault",
};

export const SingleItemHighWidthCharts = {
    GenerationCapacityFactor: "Generation & Capacity Factor",

};

