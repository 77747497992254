import { Component, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as Highcharts from "@node_modules/highcharts";
import { Options } from "@node_modules/highcharts/highcharts";
import { DashboardItemWidgetType } from "@app/shared/common/widget/widget-consts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChartService } from "@app/shared/common/dashboard-charts/chart.service";
import xrange from "highcharts/modules/xrange";
import HBoost from "highcharts/modules/boost";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";


HBoost(Highcharts);
xrange(Highcharts);

NoDataToDisplay(Highcharts);


@Component({
    selector: "app-xrange-chart",
    templateUrl: "./xrange-chart.component.html",
    styleUrls: ["./xrange-chart.component.scss"]
})
export class XRangeChartComponent extends AppComponentBase implements OnChanges {
    @Input() divId: string;
    @Input() chartName: string;
    @Input() completedEventId: string;

    @Input() series: Highcharts.SeriesOptionsType[];
    @Input() yCategories: string[];
    @Input() tooltipFormatter: (arg: Highcharts.TooltipFormatterContextObject) => string;

    highchart: Highcharts.Chart;
    options: Options;
    chunkSize = 1; // Her seferde eklenecek veri parçası boyutu
    currentIndex = 0;

    constructor(injector: Injector, private _chartService: ChartService) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.series) {
            this.initChart();
            setTimeout(() => {
                abp.event.trigger(this.completedEventId, {
                    isCompleted: true,
                    highChart: this.highchart,
                    type: DashboardItemWidgetType.chart
                });
            }, 1000);
        }
    }

    getChartOptions(): Options {
        let that = this;

        let initialSeries = this.series.map(serie => ({
            ...serie,
            // @ts-ignore
            data: serie.data.slice(0, 500)
        }));

        console.log(initialSeries)

        return {
            chart: {
                type: "xrange"
            },
            boost: {
                useGPUTranslations: true,
                enabled: true // Boost'u etkinleştirme
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                ordinal: false,
                type: "datetime"
            },
            yAxis: {
                startOnTick: false,
                title: {
                    text: null
                },
                categories: this.yCategories,
                reversed: true,
                gridLineWidth: 0
            },
            series: initialSeries,
            tooltip: {
                formatter: function(tooltip): string {
                    return that.tooltipFormatter(this);
                }
            }
        };
    }

    setOptions() {
        this.options = this.getChartOptions();
    }

    update() {
        this.setOptions();
        this.highchart.update(this.options);
    }

    initChart() {
        this.setOptions();
        let chart = document.querySelector("#" + this.divId + " #" + this.chartName);

        // @ts-ignore
        this.highchart = Highcharts.chart(chart, this.options);
        this.addDataChunks();

        // Retry işlemini gerçekleştiren bir fonksiyon tanımlayın
        this._chartService.retryUntilSuccess(
            1000, // İnterval (ms)
            10, // Maksimum deneme sayısı
            () => Highcharts.charts.find((v) => v["renderTo"].id == this.chartName) !== undefined, // Koşul
            () => {
                const xRangeChart = Highcharts.charts.find((v) => v["renderTo"].id == this.chartName);
                if (xRangeChart) {
                    xRangeChart.reflow();
                }
            }
        );

        setTimeout(() => {
            abp.event.trigger(this.completedEventId, {
                isCompleted: true,
                highChart: this.highchart,
                type: DashboardItemWidgetType.chart
            });
        }, 1000);
    }

    //series da çok kayıt olduğundan highchart chart'ı oluşturmuyor. Bu method sayesinde parça halinde çizdirebiliyoruz.
    addDataChunks() {
        let currentIndex = 500;
        const chunkSize = 500;

        const addNextChunk = () => {
            if (!this.highchart || !this.highchart.series) return;

            this.series.forEach((serie, i) => {
                // Highcharts grafiğindeki series mevcut değilse atla
                if (!this.highchart.series[i]) return;

                // @ts-ignore
                const dataChunk = serie.data.slice(currentIndex, currentIndex + chunkSize);

                dataChunk.forEach(point => {
                    // `true` ile hemen yeniden çiz
                    this.highchart.series[i].addPoint(point, false);
                });
            });

            currentIndex += chunkSize;

            // @ts-ignore
            if (currentIndex < this.series[0].data.length) {
                setTimeout(addNextChunk, 100); // 100 ms gecikmeli ekle
            } else {
                this.highchart.redraw(); // Tüm parçalar eklendikten sonra yeniden çiz
            }
        };

        addNextChunk(); // Fonksiyonu başlat
    }
}

