<div *ngIf="data" class="card-body p-0">
    <div class="row p-0 m-0">
        <div *ngFor="let tbl of tables" class="col p-0 mx-1">
            <div class="table-responsived-block">
                <table class="table align-middle data-grid-table">
                    <thead class="data-grid-thead">
                    <tr *ngIf="!tbl.rows[0]?.columns[0]?.solarDeviceType && tbl.rows[0]?.columns[0]?.solarDeviceType != 'inverter'">
                        <td [colSpan]="tbl.headers.length" *ngIf="tbl.rows[0]?.columns[0]?.nominalPower"
                            class="data-grid-thead-text text-center p-3">
                            Capacity (Max: {{ tbl.rows[0]?.columns[0]?.nominalPower }}  MW)
                        </td>
                    </tr>
                    <tr>
                        <th *ngFor="let header of tbl.headers; index as columnIndex;"
                            (click)="header.sortable && sort(columnIndex)"
                            [style.cursor]="header.sortable ? 'pointer' : 'auto'"
                            style="min-width:50px"
                            class="data-grid-thead-text text-center p-3">
                            <img *ngIf="header.sorting" src="/assets/data-grid/icons/icons-ic-arrow-down1.svg"
                                 alt="icons/ic_arrow_down1" />
                            {{ header.name }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of tbl.rows"
                        style="border-bottom: solid 1px !important; border-color: #919eab3d !important;">
                        <td *ngFor="let column of row.columns; index as i;" class="p-0">
                            <div [ngSwitch]="column.type">
                                <div *ngSwitchCase="DataGridColumnType.AvatarAndName">
                                    <div class="stack">
                                        <div class="avatar">
                                            <div class="avatar-container">
                                                <img
                                                    [src]="column.imageUrl ? column.imageUrl : '/assets/data-grid/icons/icons-ic-replace.svg'"
                                                    class="iconsic_replace"
                                                    alt="icons/ic_replace" />
                                            </div>
                                            <div *ngIf="column.badgeStatus" class="badge-status"></div>
                                        </div>
                                        <div class="name2 publicsans-normal-charade-14px">{{ column.value }}</div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="DataGridColumnType.Progress"
                                     class="d-flex flex-column align-items-center">

                                    <div class="table-cell-3 table-cell-7">
                                        <div class="badge-progress">

                                            <div [class]="'container-1 container-2 '"
                                                 [style]="'background-color: ' + column.progressColor + ';'">
                                                <div class="progress-2"
                                                     [style]="'width: ' + column.progressPercentage + '%; background-color: ' + column.progressBackgroundColor + ';'"></div>
                                            </div>
                                        </div>
                                        <div
                                            class="number-percent2 publicsans-normal-pale-sky-12px"
                                            style="width: 10px; padding-left: 10px;">{{ column.value ? column.value.toLocaleString() : "-" }}
                                        </div>
                                    </div>


                                    <!--                                    <progress-bar-->
                                    <!--                                        [barValue]="column.value || 0"-->
                                    <!--                                        [percentage]="column.progressPercentage"-->
                                    <!--                                        [color]="column.progressColor"-->
                                    <!--                                        [precisionSize]="column.precisionSize"-->
                                    <!--                                        class="w-100">-->
                                    <!--                                    </progress-bar>-->
                                </div>
                                <div *ngSwitchCase="DataGridColumnType.Number"
                                     class="d-flex flex-column align-items-center">
                                    <div
                                        class="number-percent2 publicsans-normal-pale-sky-12px">{{ column.value ? column.value.toLocaleString() : "-" }}
                                    </div>
                                </div>
                                <div *ngSwitchCase="DataGridColumnType.Text"
                                     class="d-flex flex-column align-items-center"
                                     [style.background-color]="getColorForInverterPrValue(column)">
                                    <div class="avatar-badge">
                                        <div
                                            [className]="getUnitStatusColor(column.onePactResponse,column.powerPlantType)"></div>
                                    </div>
                                    <div

                                        class="number-percent2 publicsans-normal-pale-sky-12px"
                                        [innerHTML]="column.value && sanitizeHtml(column.value.toLocaleString())">
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
